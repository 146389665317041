@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */
html {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
html::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
